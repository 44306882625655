export const possibleSectionsLabelMap: { [key: string]: string } = {
  description: 'Description',
  visual_collections_page: 'Images',
  rooms: 'Chatrooms',
  character_worlds: 'Characters',
};

export const possibleExtraSectionsLabelMap: { [key: string]: string } = {
  text: 'Text',
};
